<template>
  <div class="cell-demo" style="min-height: 560px">

    <a-space wrap>
      <a-input :style="{width:'320px'}" v-model="token" placeholder="请输入Token" allow-clear>
      </a-input>
      <a-input-search v-model="searchValue" :style="{width:'320px'}" placeholder="请输入标题" @click="getData1"
                      search-button/>
    </a-space>


    <a-table :data="form.noData ? [] : data.list"
             :bordered="form.border"
             :hoverable="form.hover"
             :stripe="form.stripe"
             :loading="form.loading"
             :show-header="form.tableHeader"
             :pagination="data.pagination"
             :row-selection="form.checkbox ? rowSelection : undefined">
      <template #columns>
        <a-table-column title="BookNo" data-index="BookNo"></a-table-column>
        <a-table-column title="Title" data-index="Title"></a-table-column>
        <a-table-column title="CreateDate" data-index="CreateDate"></a-table-column>
        <a-table-column title="ISBN" data-index="ISBN"></a-table-column>
        <a-table-column title="Creator" data-index="Creator"></a-table-column>
        <a-table-column title="Path">
          <template #cell="{ record }">
            <a :href="record.Path" target="_blank">打开</a>
          </template>
        </a-table-column>
        <a-table-column title="CoverImage">
          <template #cell="{ record }">
            <a-image width="80" :src="record.CoverImage" />
          </template>
        </a-table-column>
      </template>
    </a-table>


  </div>

</template>

<script setup>

import {getData} from '../api/index'
import {onMounted, ref} from "vue";
import {Notification} from '@arco-design/web-vue';

import {reactive} from 'vue';


const form = reactive({
  border: true,
  borderCell: false,
  hover: true,
  stripe: false,
  checkbox: true,
  loading: false,
  tableHeader: true,
  noData: false
});

const rowSelection = reactive({
  type: 'checkbox',
  showCheckedAll: true
});


let data = reactive({
  list: [], searchValue: '1231', pagination: {
    current: 1,//分页的索引
    total: 10000,
    pageSize: 10, //每页中显示10条数据
    showSizeChanger: false,//不设置每页的数据条数
    size: "small",
    pageSizeOptions: ["10", "20", "50", "100"]
  }
})
let searchValue = ref("")
let token = ref("")

function getData1() {
  if (token.value == "") {
    Notification.info('请输入Token!')
    return
  }
  if (searchValue.value == "") {
    Notification.info('请输入标题!')
    return
  }
  if (searchValue.value != "") {
    const param = {
      q: 'Title:' + searchValue.value,
      token: token.value
    }
    getData(param).then((res) => {
      data.pagination.total = res.data.total
      data.list = res.data.rows
    })
  }

}

onMounted(() => {
  // getData1()
})

</script>

<style scoped>
.cell-demo {
  margin-top: 24px;
  padding: 48px;
  border: 1px solid var(--color-border);
  border-radius: 2px 2px 0 0;
}
</style>
