import http from '../utils/http'


export function getData(params){
    return http.post(`cbook/select2`,params)
}
//post请求
export function postFormAPI(params){
    return http.post(`cbook/select2`,params)
}
