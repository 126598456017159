import { createApp } from 'vue'
import App from './App.vue'
import installElementPlus from './plugins/element'

import ArcoVue from '@arco-design/web-vue';
import '@arco-design/web-vue/dist/arco.css';

import TDesign from 'tdesign-vue-next';
// 引入组件库全局样式资源
import 'tdesign-vue-next/es/style/index.css';

const app = createApp(App)
app.use(ArcoVue);
app.use(TDesign);
installElementPlus(app)
app.mount('#app')